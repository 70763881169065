import * as ethers from 'ethers';
import { Zoom } from 'zoom-next';
import abi from '../../contracts/generic-abi.json';
import zoom4 from '../../contracts/Zoom4.json';

export const ZOOM3 = '0xcD518b70Cafe0Acea78D3Dc2E4c8af082b6de0Ff';
const contractAddr = process.env.REACT_APP_EXP_CARD_CONTRACT;
const rpc = process.env.REACT_APP_RPC_CARD_PROVIDER;

let provider;
export const rpcProvider = () => {
  if (provider === undefined) {
    return (provider = new ethers.providers.JsonRpcProvider(rpc));
  } else return provider;
};

export const getZoomContract = () => {
  const zoomContract = new ethers.Contract(ZOOM3, zoom4.abi, rpcProvider());
  return zoomContract;
};

const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());

export const getCollectionsNumber = async () => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const nrOfCollections = await contract.collectionCount().then().catch();
  return nrOfCollections;
};

export const getCollectionData = async (collectionId) => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const collectionData = await contract
    .collections(collectionId)
    .then()
    .catch();
  return collectionData;
};

export const getCollecitonIdByTokenId = async (tokenId) => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const collectionIdByTokenId = await contract
    .tokenIdToCollectionId(tokenId)
    .then()
    .catch();
  return collectionIdByTokenId;
};

export const getCollections = async () => {
  const numberOfCollections = await getCollectionsNumber();
  //console.log(numberOfCollections.toNumber(), "nr of collections");

  const nrOfCollections = numberOfCollections.toNumber();

  const collections = [];
  for (let i = 0; i < nrOfCollections; i++) {
    const collectionData = await getCollectionData(i);
    //console.log(collectionData, "collection data");
    collections.push({
      id: collectionData[0].toNumber(),
      name: collectionData[2],
      uri: collectionData[3],
    });
  }

  //console.log(collections, "collections");
  return collections;
};

export const balanceOfZoom = async (walletAddress, contractAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];

  const contract = new ethers.Contract(contractAddress, abi.abi, rpcProvider());
  const call = zoomLibrary.addCall(
    contract,
    ['balanceOf', [walletAddress]],
    'balanceOf(address) returns (uint256)'
  );
  calls.push(call);

  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = {};

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0];
      const tokenCount = decoded.toNumber();
      if (tokenCount === 0) return;
      result[contractAddress] = tokenCount;
    } catch (error) {
      console.log(`Cannot decode response for ${contractAddress}`);
    }
  });
  console.log(result, ' result balance of zoom');
  return result;
};

export const nameOfContractOfZoom = async (contractAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];

  const contract = new ethers.Contract(contractAddress, abi.abi, rpcProvider());
  const call = zoomLibrary.addCall(
    contract,
    ['name'],
    'name() returns (string)'
  );
  calls.push(call);

  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0];
      const contractName = decoded.toString();
      result.push({
        contractAddress: contractAddress,
        contractName: contractName,
      });
    } catch (error) {
      console.log(`Cannot get name for ${contractAddress[index]}`);
    }
  });
  return result;
};

export const tokenOfOwnerByIndexZoom = async (
  walletAddress,
  ownedToknIndexes
) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  Object.keys(ownedToknIndexes).forEach((address, index) => {
    const contract = new ethers.Contract(address, abi.abi, rpcProvider());
    for (let i = 0; i < ownedToknIndexes[address]; i++) {
      const call = zoomLibrary.addCall(
        contract,
        ['tokenOfOwnerByIndex', [walletAddress, i]],
        'tokenOfOwnerByIndex(address, uint256) returns (uint256)'
      );
      calls.push({ contractAddress: address, call });
    }
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call.call)[0];
      result.push({
        contractAddress: call.contractAddress,
        tokenId: decoded.toNumber(),
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};

export const tokenUrisZoom = async (tokens) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  tokens.forEach((token) => {
    const contract = new ethers.Contract(
      token.contractAddress,
      abi.abi,
      rpcProvider()
    );
    const call = zoomLibrary.addCall(
      contract,
      ['tokenURI', [token.tokenId]],
      'tokenURI(uint256) returns (string)'
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0].toString();
      result.push({
        ...tokens[index],
        tokenUri: decoded,
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};
