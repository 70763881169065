import React from "react";
import "../App.css";
import logo from "../assets/images/logo.svg";
import arrow from "../assets/images/arrow.png";
import huIcon from "../assets/images/HU.png";
import LanguagePicker from "./LanguagePicker";
import cclogo from "../assets/images/CamelCoding-logo.svg"
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate("/home")
  }

  return (
    <div className="header">
      <div className="logo-div">
        <img src={logo} className="logo" onClick={goToHomepage}></img>
      </div>
      <LanguagePicker/>
    </div>
  );
};

export default Header;
