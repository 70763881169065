import React from "react";
import config from "../config.json";
import ccLogo from "../assets/images/CamelCoding-logo.svg";
import galaxisLogo from "../assets/images/Galaxis_white logo.svg";

const Footer = () => {
  const ccLink = (
    <a href="https://www.camelcoding.com/" target="_blank" className="ccLink">
      CamelCoding
    </a>
  );

  const cc = <img src={ccLogo} className="ccLogo"></img>;
  const galaxis = <img src={galaxisLogo} className="galaxisLogo"></img>;

  // const galaxisLink = (
  //   <a href="https://galaxis.xyz/#/" target="_blank" className="galaxisLink">
  //     Galaxis
  //   </a>
  // );

  const goToCamelCoding = () => {
    window.open("https://camelcoding.com/", "_blank");
  }

  const goToGalaxis = () => {
    window.open("https://galaxis.xyz/#/", "_blank");
  }

  return (
    <div className="footer" style={{ color: config.CAMELCODING.textColor }}>
      <div className="ccHolder">
        <p className="poweredBy" style={{ color: config.MUZEUM.textColor }}>POWERED BY: {ccLink}</p>
        {/* <div onClick={goToCamelCoding} style={{cursor: 'pointer'}}>{cc}</div> */}
      </div>
      {/* <div>
        <p className="poweredBy">INSPIRED BY:</p>
        <div onClick={goToGalaxis} style={{cursor: 'pointer'}}>{galaxis}</div>
      </div> */}
    </div>
  );
};

export default Footer;
