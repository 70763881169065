import axios from 'axios';
import {
  balanceOfZoom,
  nameOfContractOfZoom,
  tokenOfOwnerByIndexZoom,
  tokenUrisZoom,
  getCollecitonIdByTokenId,
} from './contractMethdos';

export async function getTokenData(walletAddress, contractAddress) {
  // balanceOfAll;
  const balanceOfAll = await balanceOfZoom(walletAddress, contractAddress);
  console.log(balanceOfAll, ' balance of all');

  //tokenOfOwnerByIndex
  const tokens = await tokenOfOwnerByIndexZoom(walletAddress, balanceOfAll);
  console.log(tokens, ' tokens');
  //tokenuri
  const tokenUris = await tokenUrisZoom(tokens);

  return tokenUris;
}

export async function getMetaData(walletAddress, contractAddress) {
  const tokenData = await getTokenData(walletAddress, contractAddress);
  const nameOfContract = await nameOfContractOfZoom(contractAddress);
  //console.log(nameOfContract, "name of contract");

  let metas = {};
  for (let i = 0; i < tokenData.length; i++) {
    for (let z = 0; z < nameOfContract.length; z++) {
      if (tokenData[i].contractAddress === nameOfContract[z].contractAddress) {
        if (metas[nameOfContract[z].contractName] == null) {
          let array = [];
          metas[nameOfContract[z].contractName] = array;
        }
        metas[nameOfContract[z].contractName].push(tokenData[i].tokenUri);
      }
    }
  }

  //console.log(metas, 'metas')
  return metas;
}

export async function collectionIdByTokenId(walletAddress, contractAddress) {
  const tokenData = await getTokenData(walletAddress, contractAddress);
  //console.log(tokenData, "tokenData");

  let tokenIds = Object.values(tokenData).map((data) => data.tokenId);

  //console.log(tokenIds, "token ids");

  let mintedCollectionsId = [];
  for (let i = 0; i < tokenIds.length; i++) {
    const collectionId = await getCollecitonIdByTokenId(tokenIds[i]);
    mintedCollectionsId.push(collectionId.toNumber());
  }

  //console.log(mintedCollectionsId, 'minted idssssssssss');
  return mintedCollectionsId;
}
